<template>
    <v-chart class="chart" :option="option" />
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
]);

export default {
    props: ['timeline', 'lightData'],
    components: {
        VChart
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    data() {
        return {
            option: {
                title: {
                    text: 'Device Light',
                        left: 'center',
                        top: 20,
                        textStyle: {
                            // color: '#333',
                            fontSize: 14
                        }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    color: "white",
                    fontSize: 12,
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { type: ['line', 'bar'] },
                        saveAsImage: {}
                    },
                    color: "white"
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.timeline,
                    axisLabel: {
                        color: "white",
                                    fontSize: 12,
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} LUX',
                        color: "white",
                                    fontSize: 12,
                    }
                },
                series: [
                    {
                        name: 'light',
                        type: 'line',
                        color: 'yellow',
                        data: this.lightData,
                        markPoint: { data: [{ type: 'max', name: 'Max' }, { type: 'min', name: 'Min' }] },
                    },
                   
                ]
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    height: 65vh;
}
</style>