<template>
    <div>
        <v-card>
            <div v-if="loading">
                <v-card-text>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="12" md="12">
                            <v-progress-circular :size="35" :width="7" color="primary"
                                indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
            <div v-else>
                <div v-if="this.device && this.device.id">
                    <SensiLightChart :timeline="lightTimeline" :lightData="lightReadings" />
                </div>
                <div v-else>
                    <span style="color: grey">No device found.</span>
                </div>

            </div>
        </v-card>

    </div>
</template>

<script>
import SensiLightChart from "../Charts/SensiLightChart.vue";
export default {
    props: ["container"],
    components: {
        SensiLightChart,
    },
    data: () => ({
        loading: false,
        lightTimeline: [],
        lightReadings: [],
        device: {}
    }),
    mounted() {
        this.getContainerDevice();
    },
    methods: {
        async getContainerDevice() {
            this.loading = true
            if (this.container && this.container.sensorId) {
                this.device = await this.$API.getSensorDeviceById(this.container.sensorId)
                await this.loadLightReadings()
            }
            this.loading = false
        },
        async loadLightReadings() {
            let res = await this.$API.getDeviceLightReadings(this.device.id);
            if (res && res.length > 0) {
                this.lightReadings = res.map(x => { return x.light.toFixed(2) })
                this.lightTimeline = res.map(x => { return x.readingDate.replace('T', ' ').replace('Z', '') })
            }
        },
    }
}
</script>

<style>

</style>